.order-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.order-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.order-modal-details {
  margin-top: 20px;
}

.order-modal-details p {
  margin: 2px;
}
.mx-auto{
  margin-left:auto;
  margin-right:auto;
}
.order-modal-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.order-item-image {
  min-width: 100px;
  min-height: 100px;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  cursor: pointer;
}

.order-item-image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 5px;
}

.order-item-details p {
  margin: 0;
}

.order-item {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: box-shadow 0.3s ease;
}

.order-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shop-area.items-center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.order-id,
.order-date,
.order-status {
  margin-bottom: 5px;
}

.order-items {
  margin-top: 10px;
}

.order-item-detail {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.order-item-image img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 3px;
}

.order-item-info {
  display: flex;
  flex-direction: column;
}