.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    padding-top: 20px;
    overflow: scroll;
}

.custom-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
}

.custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 10px;
}

.custom-modal-title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 500;
}

.custom-modal-body {
    margin-top: 15px;
}

.billing-back-btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.billing-btn{
    display: flex;
    gap: 10px;
}

.billing-btn button {
    background-color: #ffa200;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.billing-btn button:hover {
    background-color: #110c05;
}

.close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    line-height: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}